<template>
<div id='QuickSearchBox'
  :class="{'search':true, 'mobile-view':isMobileSearch}"
  class="d-flex align-items-left"
  ref="searchContainer" >
  <span class="search-icon">
    <i class="simple-icon-magnifier"></i>
  </span>
  <div class="d-inline-block flex-grow-1">
    <autocomplete
    :search="search"
    :placeholder="'Search ' + defaultScope"
    :get-result-value="getResultValue"
    @submit="handleSubmit"
    :debounce-time="1000">
      <template #result="{ result, props }" >
        <li v-bind="props" class="autocomplete-result">
          <!-- ResultNumber = -1 is for 'No results found' -->
          <span v-if="result.resultNumber !== -1" class="autocomplete-result-icon">
            <i :class="getResultTypeIcon(result.resultType)" />
          </span>
          <span class="autocomplete-result-descriptor">
          {{ result.resultDescriptor }}
          </span>
        </li>
      </template>
  </autocomplete>
  </div>
  <span id='ErrorIcon' class="d-inline-block flex-shrink-1 alert-icon" v-if="error">
    <i class="simple-icon-exclamation"></i>
    <b-popover target="ErrorIcon" triggers="hover" placement="leftbottom" :title="error">
  </b-popover>
  </span>
  <b-popover target="QuickSearchBox" triggers="hover" placement="rightbottom">
    <template #title>Quick Search Options</template>
      Company Search: <b>"/company 31006"</b><br />
      Employee Search: <b>"/employee john"</b><br />
      <br />
  </b-popover>
</div>
</template>

<script>

import { BeaconapiUrl } from '../../constants/config'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import axios from 'axios'

export default {
  name: 'ScopedSearch',
  props: {
    defaultScope: String,
    minChars: {
      type: Number,
      default: 3
    }
  },
  components: {
    Autocomplete
  },
  data () {
    return {
      value: '',
      isMobileSearch: false,
      error: null
    }
  },
  methods: {
    // The autocomplete component expects the search function to return a promise that resolves to an array of search results
    async search (input) {
      this.value = input
      if (!input) {
        this.clearErrors() // clears error messages if user clears the textbox
        return []
      }
      if (input.startsWith('/') && !input.includes(' ')) {
        return [] // if user is manually entering a scope, don't start searching until after the space
      } else if (input.length < this.minChars) {
        return []
      } else {
        var searchTerm = ''
        var searchScope = this.defaultScope
        if (input.includes('/') && input.includes(' ')) {
          // separate search term from scope
          searchScope = input.substring(input.indexOf('/') + 1, input.indexOf(' '))
          searchTerm = input.substring(input.indexOf(' ') + 1, input.length).trim()
          if (searchTerm.length < this.minChars) {
            // search term still too short, don't search.
            return []
          }
        } else {
          searchTerm = input.trim()
        }
      }
      // we have a valid search term, time to hit the api
      return await this.getSearchResults(searchScope, searchTerm)
    },
    async getSearchResults (scope, searchTerm) {
      return await axios
        .get(`${BeaconapiUrl}/api/search/scoped-search?scope=${scope}&searchTerm=${searchTerm}`)
        .then((results) => {
          this.error = null
          return results.data
        })
        .catch((error) => {
          if (error.response) {
            // expected error with message from API
            this.error = error.response.data
          } else if (error.request) {
            this.error = 'No response received from search'
          } else {
            // unexpected error
            this.error = error.message
          }
          return []
        })
    },
    // Our API returns a SearchResultDTO
    // We want to display the ResultDescriptor field
    getResultValue (result) {
      return result.resultDescriptor
    },

    // push the selected result onto the router, based on the type
    handleSubmit (result) {
      switch (result.resultType) {
        case 'Company':
          this.$router.push({ name: 'EditCompany', params: { companyID: result.resultIdentifier } })
          break
        case 'Employee':
          this.$router.push({ name: 'employee-roles-and-permissions', params: { employeeID: result.resultIdentifier } })
          break
        default:
          console.log('Invalid search result type selected.')
      }
    },
    clearErrors () {
      this.error = null
    },
    getResultTypeIcon (resultType) {
      switch (resultType) {
        case 'Company':
          return 'iconsminds-office'
        case 'Employee':
          return 'iconsminds-male-female'
        default:
          return ''
      }
    }
  }
}
</script>
