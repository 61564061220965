import { adminRoot, RouteHistory, CompanyContext, AffiliateContext } from './config'
import { UserRole } from '../utils/auth.roles'
import bus from '../main'

const data = [{
  id: 'dashboard',
  icon: 'iconsminds-dashboard',
  label: 'menu.dashboard',
  to: `${adminRoot}/dashboard`,

  // roles: [UserRole.Admin, UserRole.Editor],
  subs: [
    {
      icon: 'simple-icon-bell',
      label: 'menu.my-notifications',
      to: `${adminRoot}/user/notifications`
    },
    {
      icon: 'simple-icon-credit-card',
      label: 'menu.leads-dashboard',
      to: `${adminRoot}/company/leads`
      // meta: { permission: 'Leads Dashboard' } // uncomment out when ready to limit who can see Leads Dashboard
    }
  ]
},
{
  id: 'company',
  icon: 'iconsminds-office',
  label: 'menu.company',
  to: `${adminRoot}/company`,
  subs: []
},
{
  id: 'application',
  icon: 'iconsminds-file-clipboard-file---text',
  label: 'menu.application',
  to: `${adminRoot}/application-grid`,
  subs: []
},
{
  id: 'affiliates',
  icon: 'iconsminds-box-with-folders',
  label: 'menu.affiliates',
  to: `${adminRoot}/affiliates`,
  meta: { permission: 'Affiliates' },
  subs: [
    {
      id: 'affiliates-grid',
      icon: 'iconsminds-box-with-folders',
      label: 'menu.affiliates',
      to: `${adminRoot}/affiliates/affiliates-grid`,
      meta: { permission: 'Affiliates' }
    }
  ]
},
{
  id: 'history',
  icon: 'iconsminds-library',
  label: 'menu.history',
  to: `${adminRoot}/history`,
  subs: [
    { label: 'menu.no-history', to: '', newWindow: false }
  ]
},
{
  id: 'admin',
  icon: 'iconsminds-engineering',
  label: 'menu.admin',
  to: `${adminRoot}/admin`,
  subs: [
    {
      id: 'admin-accounting',
      label: 'menu.accounting',
      subs: [
        {
          id: 'admin-payments',
          icon: 'iconsminds-mail-money',
          label: 'menu.payments',
          to: `${adminRoot}/admin/payments`
        }
      ]
    },
    {
      id: 'admin-administration',
      icon: 'iconsminds-male-female',
      label: 'menu.administration',
      to: `${adminRoot}/admin/employees`,
      subs: [
        {
          id: 'admin-email-templates',
          icon: 'iconsminds-box-with-folders',
          label: 'menu.email-templates',
          to: `${adminRoot}/admin/email-templates`,
          meta: { permission: 'Email Templates' }
        },
        {
          id: 'admin-navigator-users',
          icon: 'iconsminds-arrow-inside',
          label: 'menu.navigator-users',
          to: `${adminRoot}/admin/user-logins`
        },
        {
          id: 'admin-view-permissions',
          icon: 'iconsminds-key-lock',
          label: 'menu.permissions',
          to: `${adminRoot}/admin/employees/Permissions`,
          meta: { permission: 'Employee Admin' }
        },
        {
          id: 'admin-programs',
          icon: 'iconsminds-box-with-folders',
          label: 'menu.programs',
          to: `${adminRoot}/admin/programs`,
          meta: { permission: 'Programs' }
        },
        {
          id: 'admin-view-roles',
          icon: 'iconsminds-key-lock',
          label: 'menu.roles',
          to: `${adminRoot}/admin/employees/roles`,
          meta: { permission: 'Employee Admin' }
        },
        {
          id: 'admin-services',
          icon: 'iconsminds-box-with-folders',
          label: 'menu.services',
          to: `${adminRoot}/admin/services`,
          meta: { permission: 'Services' }
        },
        {
          id: 'admin-users-by-permissions',
          icon: 'iconsminds-id-card',
          label: 'menu.horizon-users',
          to: `${adminRoot}/admin/employees/manage-employees`,
          meta: { permission: 'Employee Admin' }
        }
      ]
    },
    {
      id: 'admin-business-development',
      icon: 'iconsminds-box-with-folders',
      label: 'menu.business-development',
      to: `${adminRoot}/admin/business-development`,
      subs: [
        {
          id: 'admin-request-for-proposals',
          icon: 'iconsminds-box-with-folders',
          label: 'menu.request-for-proposals',
          to: `${adminRoot}/admin/request-for-proposal`,
          meta: { permission: 'Sales RFP' }
        }
      ]
    },
    {
      id: 'admin-customer-care',
      label: 'menu.customer-care',
      subs: [
        {
          id: 'admin-round-robin',
          icon: 'iconsminds-hipster-men',
          label: 'menu.round-robin',
          to: `${adminRoot}/admin/round-robin/2`,
          meta: { permission: 'Sales Round Robin' }
        },
        {
          id: 'application-admin-grid',
          icon: 'iconsminds-file-clipboard-file---text',
          label: 'menu.applications',
          to: `${adminRoot}/admin/application-grid`,
          meta: { permission: 'Application Dashboard' }
        },
        {
          id: 'admin-reports-grid',
          icon: 'iconsminds-file-edit',
          label: 'menu.reports',
          to: `${adminRoot}/admin/reports`
        }
      ]
    },
    {
      id: 'admin-customer-experience',
      label: 'menu.customer-experience',
      subs: [
        {
          id: 'admin-accreditation',
          icon: 'iconsminds-hipster-men',
          label: 'menu.round-robin',
          to: `${adminRoot}/admin/round-robin/1`
        }
      ]
    }
  ]
}
]

// listen for the refresh history event fired from the router.js
bus.$on('repainthistory', () => {
  // dynamically get history and assign them to data
  var historysubs = data.find(d => d.id === 'history').subs
  // TZ this line above is how we should get into the menu js json and modify it
  // so i will need to create a method like this one, bus.on('companyContext') etc.

  // clear the current list because we always pull this from localstorage
  historysubs.splice(0, historysubs.length)

  if (localStorage.getItem(RouteHistory) !== null) {
    var histarray = JSON.parse(localStorage.getItem(RouteHistory))
    for (var i = 0; i < histarray.length; i++) {
      historysubs.push({ label: histarray[i].label, to: histarray[i].path, newWindow: false })
    }
  }
})

bus.$on('buildcompanycontext', () => {
  var companyMenuSubs = data.find(d => {
    return d.id === 'company'
  }).subs
  var companyContextMenu = companyMenuSubs.find(s => s.id === 'dynamic-lead-menu')
  if (companyContextMenu !== undefined) {
    companyMenuSubs.splice(companyMenuSubs.indexOf(companyContextMenu), 1)
  }
  if (companyMenuSubs.find(s => s.id === 'advanced-search') === undefined) {
    companyMenuSubs.unshift(
      { id: 'advanced-search', icon: 'iconsminds-binocular', label: 'Advanced Search', to: `${adminRoot}/company/advanced-search`, class: 'test' }
    )
  }

  var companyID = localStorage.getItem(CompanyContext)
  // TZ - adding a check at the end of the line below to make sure that the companyID is not 0, which could happen when there are issues with creating a company.
  if (companyID !== null && companyID !== undefined && companyID !== 'null' && companyID !== 'NaN' && companyID !== 'undefined' && companyID !== '0') {
    // Only add dynamic menu if it does not already exist
    var myJson = getLeadMenuJson(companyID)
    // Not a fan of hardcoding the index but since it will always be the second option ¯\_(ツ)_/¯
    companyMenuSubs.splice(2, 0, myJson)
  }
})

function getLeadMenuJson (companyID) {
  var menuJson = {
    id: 'dynamic-lead-menu',
    label: 'Company #' + companyID,
    to: `${adminRoot}/company/` + companyID + '/edit-company',
    subs: [
      {
        icon: 'simple-icon-info',
        label: 'Information',
        to: `${adminRoot}/company/` + companyID + '/edit-company',
        newWindow: false
      },
      {
        icon: 'simple-icon-phone',
        label: 'Phone',
        to: `${adminRoot}/company/` + companyID + '/phone',
        newWindow: false
      },
      {
        icon: 'simple-icon-map',
        label: 'Address',
        to: `${adminRoot}/company/` + companyID + '/address',
        newWindow: false
      },
      {
        icon: 'simple-icon-people',
        label: 'Contact',
        to: `${adminRoot}/company/` + companyID + '/contact',
        newWindow: false
      },
      {
        icon: 'simple-icon-organization',
        label: 'Affiliate',
        to: `${adminRoot}/company/` + companyID + '/affiliate',
        newWindow: false
      },
      {
        icon: 'simple-icon-credit-card',
        label: 'Quote',
        to: `${adminRoot}/company/` + companyID + '/leadQuote',
        newWindow: false
      },
      {
        icon: 'simple-icon-docs',
        label: 'Activity',
        to: `${adminRoot}/company/` + companyID + '/activity',
        newWindow: false
      },
      {
        icon: 'simple-icon-event',
        label: 'Follow Up',
        to: `${adminRoot}/company/` + companyID + '/follow-up',
        newWindow: false
      },
      {
        icon: 'simple-icon-list',
        label: 'Work Flow Step History',
        to: `${adminRoot}/company/` + companyID + '/work-flow-step-history',
        newWindow: false
      },
      {
        icon: 'simple-icon-login',
        label: 'User Logins',
        to: `${adminRoot}/company/` + companyID + '/user-logins',
        newWindow: false
      }
      // Change request functionality will not be implemented in phase 1 so i have hidden this code.
      // {
      //   icon: 'simple-icon-people',
      //   label: 'Change Request',
      //   to: `${adminRoot}/company/` + companyID + '/change-request',
      //   newWindow: false
      // }
    ]
  }

  return menuJson
}

bus.$on('buildaffiliatecontext', () => {
  var affiliatesMenuSubs = data.find(d => d.id === 'affiliates').subs
  var affiliateContextMenu = affiliatesMenuSubs.find(s => s.id === 'dynamic-affiliate-menu')
  if (affiliateContextMenu !== undefined) {
    affiliatesMenuSubs.splice(affiliatesMenuSubs.indexOf(affiliateContextMenu), 1)
  }

  // LocalStorage holds null as a string called 'null'
  // Also make sure the parseInt from setAffiliateContext() succeeded
  var affiliateID = localStorage.getItem(AffiliateContext)
  if (affiliateID !== null && affiliateID !== undefined && affiliateID !== 'null' && affiliateID !== 'NaN' && affiliateID !== 'undefined') {
    // Only add dynamic menu if it does not already exist
    var myJson = getAffiliateMenuJson(affiliateID)
    affiliatesMenuSubs.splice(affiliatesMenuSubs.findIndex(a => a.id === 'affiliates-affiliates') + 1, 0, myJson)
  }
})

function getAffiliateMenuJson (affiliateID) {
  var menuJson = {
    id: 'dynamic-affiliate-menu',
    label: 'Affiliate #' + affiliateID,
    to: `${adminRoot}/affiliates/edit-affiliate/${affiliateID}`,
    subs: [
      {
        icon: 'simple-icon-info',
        label: 'Information',
        to: `${adminRoot}/affiliates/edit-affiliate/${affiliateID}`,
        newWindow: false
      },
      {
        icon: 'simple-icon-people',
        label: 'Contact',
        to: `${adminRoot}/affiliates/affiliate/${affiliateID}/contact`,
        newWindow: false
      },
      {
        icon: 'simple-icon-phone',
        label: 'Phone',
        to: `${adminRoot}/affiliates/affiliate/${affiliateID}/phone`,
        newWindow: false
      }
    ]
  }

  return menuJson
}

export default data
