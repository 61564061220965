<template>
<footer class="page-footer">
    <div class="footer-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <p class="mb-0 text-muted text-center">ACHC 2021</p>
                </div>
            </div>
        </div>
    </div>
</footer>
</template>
<script>
export default {

}
</script>
