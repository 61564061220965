<template>
  <nav class="navbar fixed-top" :style="{ border: navBarBorderClass }">
    <div class="d-flex align-items-center navbar-left">
      <a
        href="#"
        class="menu-button d-none d-md-block"
        @click.prevent.stop="
          changeSideMenuStatus({
            step: menuClickCount + 1,
            classNames: menuType,
            selectedMenuHasSubItems
          })
        "
      >
        <menu-icon />
      </a>
      <a
        href="#"
        class="menu-button-mobile d-xs-block d-sm-block d-md-none"
        @click.prevent.stop="changeSideMenuForMobile(menuType)"
      >
        <mobile-menu-icon />
      </a>
      <div class="d-inline-block flex-grow-1">
        <scoped-search defaultScope="Horizon" />
      </div>
      <!--<div class="d-inline-block">
        <b-dropdown
          id="langddm"
          class="ml-2"
          variant="light"
          size="sm"
          toggle-class="language-button"
        >
          <template slot="button-content">
            <span class="name">{{$i18n.locale.toUpperCase()}}</span>
          </template>
          <b-dropdown-item
            v-for="(l,index) in localeOptions"
            :key="index"
            @click="changeLocale(l.id, l.direction)"
          >{{l.name}}</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="position-relative d-none d-none d-lg-inline-block">
        <a
          class="btn btn-outline-primary btn-sm ml-2"
          target="_top"
          :href="buyUrl"
        >{{$t('user.buy')}}</a>
      </div>-->
    </div>
    <router-link class="navbar-logo" tag="a" :to="adminRoot">
      <span class="site-logo" :class="siteLogoClass"> </span>
    </router-link>
    <div class="navbar-right">
      <div class="position-relative d-inline-block" style="margin-right: 1rem;">
        <b-dropdown
          variant="empty"
          size="sm"
          right
          toggle-class="header-icon notificationButton"
          menu-class="position-absolute mt-3 notificationDropdown"
          no-caret
        >
          <template slot="button-content">
            <i class="simple-icon-bell" />
            <span class="count" v-show="activeNotifications.length > 0">{{
              activeNotifications.length
            }}</span>
          </template>
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
          >
            <div
              class="d-flex flex-column h-100"
              style="justify-content: space-between;"
            >
              <div v-if="notifications.length > 0">
                <div
                  class="d-flex flex-row mb-3 pb-3 border-bottom"
                  v-for="notification in notifications"
                  :key="`notification-${notification.horizonNotificationID}`"
                >
                  <b-col cols="11">
                    <div class="notificationBody pr-2">
                      <p
                        v-if="notification.notificationURL === null"
                        class="text-default font-weight-medium"
                        v-html="notification.notificationText"
                      ></p>
                      <a
                        v-else
                        class="notificationLink"
                        v-html="notification.notificationText"
                        :href="notification.notificationURL"
                        target="_blank"
                        @click="updateNotification(notification, 'read')"
                      >
                      </a>
                      <p class="text-muted mb-0 text-small">
                        {{ notification.dateCreatedHumanized }}
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="1" align-self="center">
                    <div
                      class="activeDot"
                      v-if="notification.active"
                      @click="updateNotification(notification, 'read')"
                    >
                      x
                    </div>
                  </b-col>
                </div>
              </div>
              <div v-else>
                <div class="pl-2 pr-2">
                  <p class="text-muted font-weight-medium mb-1 text-center">
                    You currently have no new notifications
                  </p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <b-link
                  tag="a"
                  :to="{ name: 'notifications' }"
                  class="notificationLink mb-3"
                  style="margin: 0 auto"
                >
                  Show All
                </b-link>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </b-dropdown>
      </div>
      <div class="user d-inline-block">
        <b-dropdown
          class="dropdown-menu-right"
          right
          variant="empty"
          toggle-class="p-0"
          menu-class="mt-3"
          no-caret
        >
          <template slot="button-content">
            <span class="name mr-1">{{ currentUser.name }}</span>
            <!--<span class="name mr-1">bryan</span>-->
            <span>
              <img :alt="currentUser.name" :src="getprofilepic()" />
              <!--<img :alt="currentUser.name" :src="currentUser.img" />-->
            </span>
          </template>
          <!-- <b-dropdown-item>Account</b-dropdown-item> -->
          <!--
          <b-dropdown-item>Features</b-dropdown-item>
          <b-dropdown-item>History</b-dropdown-item>
          <b-dropdown-item>Support</b-dropdown-item>
          -->

          <div class="text-center">
            <p
              style="color:#8f8f8f;font-weight:400;font-size:0.8rem"
              v-if="isDarkActive"
            >
              Light Mode
            </p>
            <p style="color:#3a3a3a;font-weight:400;font-size:0.8rem" v-else>
              Dark Mode
            </p>
            <switches
              id="tool-mode-switch"
              v-model="isDarkActive"
              theme="custom"
              class="vue-switcher-small"
              color="primary"
            />
          </div>
          <b-dropdown-divider />
          <b-dropdown-item @click="logout" class="text-center"
            >Sign out</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
  </nav>
</template>
<style>
#tool-mode-switch input[type="checkbox"] {
  left: 0;
}
</style>
<script>
import Switches from 'vue-switches'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { MenuIcon, MobileMenuIcon } from '../../components/Svg'
import {
  searchPath,
  menuHiddenBreakpoint,
  localeOptions,
  buyUrl,
  adminRoot
} from '../../constants/config'
import {
  getDirection,
  setDirection,
  getThemeColor,
  setThemeColor
} from '../../utils'
import ScopedSearch from '../../components/Search/ScopedSearch.vue'
import NotificationMixin from '../../mixins/NotificationMixin.vue'
import SystemMixin from '../../mixins/SystemMixin.vue'
import CommonMixin from '../../mixins/CommonMixin.vue'
export default {
  components: {
    'menu-icon': MenuIcon,
    'mobile-menu-icon': MobileMenuIcon,
    switches: Switches,
    'scoped-search': ScopedSearch
  },
  data () {
    return {
      searchKeyword: '',
      isSearchOver: false,
      fullScreen: false,
      menuHiddenBreakpoint,
      searchPath,
      adminRoot,
      localeOptions,
      buyUrl,
      isDarkActive: false,
      apiEnvironment: localStorage.getItem('api-environment'),
      profilepics: [
        { image: '1.jpg' },
        { image: '10.jpg' },
        { image: '11.jpg' },
        { image: '1old.jpg' },
        { image: '2.jpg' },
        { image: '3.jpg' },
        { image: '4.jpg' },
        { image: '5.jpg' },
        { image: '6.jpg' },
        { image: '7.jpg' },
        { image: '8.jpg' },
        { image: '9.jpg' }
      ]
    }
  },
  mixins: [NotificationMixin, SystemMixin, CommonMixin],
  methods: {
    ...mapMutations(['changeSideMenuStatus', 'changeSideMenuForMobile']),
    ...mapActions(['setLang', 'signOut']),
    getprofilepic () {
      var profilepicresponse = localStorage.getItem('profilepic')

      if (profilepicresponse == null) {
        return this.getDefaultProfilePic(
          localStorage.getItem('employeeName') || 'Guest User'
        )
      }
      return profilepicresponse
    },
    search () {
      this.$router.push(`${this.searchPath}?search=${this.searchKeyword}`)
      this.searchKeyword = ''
    },
    searchClick () {
      if (window.innerWidth < this.menuHiddenBreakpoint) {
        if (!this.isMobileSearch) {
          this.isMobileSearch = true
        } else {
          this.search()
          this.isMobileSearch = false
        }
      } else {
        this.search()
      }
    },
    handleDocumentforMobileSearch () {
      if (!this.isSearchOver) {
        this.isMobileSearch = false
        this.searchKeyword = ''
      }
    },
    changeLocale (locale, direction) {
      const currentDirection = getDirection().direction
      if (direction !== currentDirection) {
        setDirection(direction)
      }
      this.setLang(locale)
    },
    async logout () {
      await this.signOut().then(() => {
        this.$router.push('/user/loginAD')
      })
    },
    toggleFullScreen () {
      const isInFullScreen = this.isInFullScreen()
      var docElm = document.documentElement
      if (!isInFullScreen) {
        if (docElm.requestFullscreen) {
          docElm.requestFullscreen()
        } else if (docElm.mozRequestFullScreen) {
          docElm.mozRequestFullScreen()
        } else if (docElm.webkitRequestFullScreen) {
          docElm.webkitRequestFullScreen()
        } else if (docElm.msRequestFullscreen) {
          docElm.msRequestFullscreen()
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
      this.fullScreen = !isInFullScreen
    },
    isInFullScreen () {
      return (
        (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement &&
          document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement &&
          document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null)
      )
    },
    async onNotificationsReceived () {
      await this.getEmployeeNotifications()
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      menuType: 'getMenuType',
      menuClickCount: 'getMenuClickCount',
      selectedMenuHasSubItems: 'getSelectedMenuHasSubItems'
    }),
    siteLogoClass () {
      if (this.apiEnvironment === 'live') {
        return 'site-logo-icon'
      } else if (this.apiEnvironment === 'quality') {
        return 'site-logo-icon-qa'
      } else {
        return 'site-logo-icon-dev'
      }
    },
    navBarBorderClass () {
      if (this.apiEnvironment === null || this.apiEnvironment === undefined) {
        return 'none'
      }
      if (this.apiEnvironment === 'live') {
        return 'none'
      } else {
        return '2px solid red'
      }
    },
    activeNotifications () {
      return this.notifications.filter(n => n.active)
    }
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleDocumentforMobileSearch)
  },
  async created () {
    await this.getApiEnvironment()
    const color = getThemeColor()
    this.isDarkActive = color.indexOf('dark') > -1
    this.$socket.on('RecentNotifications', this.onNotificationsReceived)
    this.$socket.on('SendMessage', this.onNotificationsReceived)
    this.onNotificationsReceived()
  },
  watch: {
    '$i18n.locale' (to, from) {
      if (from !== to) {
        this.$router.go(this.$route.path)
      }
    },
    isDarkActive (val) {
      let color = getThemeColor()
      let isChange = false
      if (val && color.indexOf('light') > -1) {
        isChange = true
        color = color.replace('light', 'dark')
      } else if (!val && color.indexOf('dark') > -1) {
        isChange = true
        color = color.replace('dark', 'light')
      }
      if (isChange) {
        setThemeColor(color)
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }
    },
    isMobileSearch (val) {
      if (val) {
        document.addEventListener('click', this.handleDocumentforMobileSearch)
      } else {
        document.removeEventListener(
          'click',
          this.handleDocumentforMobileSearch
        )
      }
    }
  }
}
</script>
