<template>
  <div id="app-container" :class="getMenuType">
    <dirty-data-modal ref="dirtyDataModal" />
    <top-nav />
    <sidebar />
    <filter-sidebar @filterChange="fireFilterEvent" />
    <main id="app-container-body">
      <messages />
      <div class="container-fluid">
        <slot></slot>
      </div>
    </main>
    <footer-component />
  </div>
</template>

<script>
import Sidebar from '../containers/navs/Sidebar'
import TopNav from '../containers/navs/Topnav'
import Footer from '../containers/navs/Footer'
import Messages from '../containers/navs/Messages'
import { mapGetters } from 'vuex'

import TableFilterMenu from '../containers/navs/TableFilterMenu.vue'
import { filterEventBus } from '../store/FilterMenu'
import DirtyDataModal from '../components/Modals/DirtyDataModal'
import bus from '../main'

export default {
  components: {
    'top-nav': TopNav,
    sidebar: Sidebar,
    'filter-sidebar': TableFilterMenu,
    Messages: Messages,
    'footer-component': Footer,
    'dirty-data-modal': DirtyDataModal
  },
  data () {
    return {
      show: false
    }
  },

  computed: {
    ...mapGetters(['getMenuType'])
  },
  mounted () {
    bus.$on('popDirtyDataModal', (to) => {
      this.popDirtyDataModal(to)
    })

    setTimeout(() => {
      document.body.classList.add('default-transition')
    }, 100)
  },
  beforeDestroy () {
    bus.$off('popDirtyDataModal')
  },
  methods: {
    fireFilterEvent () {
      filterEventBus.$emit('filterChange')
    },
    popDirtyDataModal (to) {
      this.$refs.dirtyDataModal.show({
        title: 'WARNING',
        route: to
      })
    }
  }
}
</script>
