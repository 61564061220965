var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sidebar",on:{"click":function($event){$event.stopPropagation();return (function () {})($event)}}},[_c('div',{staticClass:"main-menu"},[_c('vue-perfect-scrollbar',{staticClass:"scroll",attrs:{"settings":{ suppressScrollX: true, wheelPropagation: false }}},[_c('ul',{staticClass:"list-unstyled"},_vm._l((_vm.filteredMenuItems(_vm.menuItems)),function(item,index){return _c('li',{key:("parent_" + index),class:{
            active:
              (_vm.selectedParentMenu === item.id && _vm.viewingParentMenu === '') ||
              _vm.viewingParentMenu === item.id
          },attrs:{"data-flag":item.id}},[(item.newWindow)?_c('a',{attrs:{"href":item.to,"rel":"noopener noreferrer","target":"_blank"}},[_c('i',{class:item.icon}),_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")]):(item.subs && item.subs.length > 0)?_c('a',{attrs:{"href":("#" + (item.to))},on:{"click":function($event){$event.preventDefault();return _vm.openSubMenu($event, item)}}},[_c('i',{class:item.icon}),_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")]):_c('router-link',{attrs:{"to":item.to},nativeOn:{"click":function($event){return _vm.changeSelectedParentHasNoSubmenu(item.id)}}},[_c('i',{class:item.icon}),_vm._v(" "+_vm._s(_vm.$t(item.label))+" ")])],1)}),0)])],1),_c('div',{staticClass:"sub-menu"},[_c('vue-perfect-scrollbar',{staticClass:"scroll",attrs:{"settings":{ suppressScrollX: true, wheelPropagation: false }}},_vm._l((_vm.filteredMenuItems(_vm.menuItems)),function(item,itemIndex){return _c('ul',{key:("sub_" + (item.id)),class:{
          'list-unstyled': true,
          'd-block':
            (_vm.selectedParentMenu === item.id && _vm.viewingParentMenu === '') ||
            _vm.viewingParentMenu === item.id
        },attrs:{"data-parent":item.id}},_vm._l((_vm.filteredMenuItems(item.subs)),function(sub,subIndex){return _c('li',{key:("sub_" + subIndex),class:{
            'has-sub-item': sub.subs && sub.subs.length > 0,
            active: _vm.$route.path.indexOf(sub.to) > -1
          }},[(
              sub.id === 'admin-affiliates' && _vm.hasAffiliateContext(_vm.menuItems)
            )?_c('hr'):_vm._e(),(sub.newWindow)?_c('a',{attrs:{"href":sub.to,"rel":"noopener noreferrer","target":"_blank"}},[_c('i',{class:sub.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(sub.label)))])]):(sub.subs && sub.subs.length > 0)?[_c('b-link',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("menu_" + itemIndex + "_" + subIndex)),expression:"`menu_${itemIndex}_${subIndex}`"}],staticClass:"rotate-arrow-icon opacity-50",attrs:{"variant":"link"}},[_c('i',{staticClass:"simple-icon-arrow-down"}),_c('span',{staticClass:"d-inline-block"},[_vm._v(_vm._s(_vm.$t(sub.label)))])]),_c('b-collapse',{attrs:{"visible":_vm.getExpandableMenus(sub.label),"id":("menu_" + itemIndex + "_" + subIndex)}},[_c('ul',{staticClass:"list-unstyled third-level-menu"},_vm._l((_vm.filteredMenuItems(
                    sub.subs
                  )),function(thirdLevelSub,thirdIndex){return _c('li',{key:("third_" + itemIndex + "_" + subIndex + "_" + thirdIndex),class:{
                    'third-level-menu': true,
                    active: _vm.$route.path === thirdLevelSub.to
                  }},[(thirdLevelSub.newWindow)?_c('a',{attrs:{"href":thirdLevelSub.to,"rel":"noopener noreferrer","target":"_blank"}},[_c('i',{class:thirdLevelSub.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(thirdLevelSub.label)))])]):_c('router-link',{attrs:{"to":thirdLevelSub.to}},[_c('i',{class:thirdLevelSub.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(thirdLevelSub.label)))])])],1)}),0)])]:_c('router-link',{attrs:{"to":sub.to}},[_c('i',{class:sub.icon}),_c('span',[_vm._v(_vm._s(_vm.$t(sub.label)))])]),(
              sub.id === 'advanced-search' ||
                sub.id === 'dynamic-affiliate-menu'
            )?_c('hr'):_vm._e()],2)}),0)}),0)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }