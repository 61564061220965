<script>
export default {
  methods: {
    getDefaultProfilePic (name, canvaStyle = {}) {
      var canvas = document.createElement('canvas')
      canvas.style.display = 'none'
      canvas.width = canvaStyle.width || '32'
      canvas.height = canvaStyle.height || '32'
      document.body.appendChild(canvas)
      var context = canvas.getContext('2d')
      context.fillStyle = canvaStyle.backgroundFillStyle || '#28638F'
      context.fillRect(0, 0, canvas.width, canvas.height)
      context.font = canvaStyle.font || 'normal 12px sans-serif'
      context.fillStyle = canvaStyle.textFillStyle || '#FFFFFF'
      // context.fillStyle = '#FFFFFF'
      var nameArray = name.split(' ')
      var initials = ''
      for (var i = 0; i < nameArray.length; i++) {
        if (i <= 1) {
          initials = initials + nameArray[i][0]
        }
      }
      const endingPosition = canvaStyle.endingPosition || 22
      if (initials.length > 1) {
        const startingPosition = canvaStyle.startingPosition || 8

        context.fillText(
          initials.toUpperCase(),
          startingPosition,
          endingPosition
        )
      } else {
        const startingPositionForSingleInitial =
          canvaStyle.startingPositionForSingleInitial || 10

        context.fillText(
          initials.toUpperCase(),
          startingPositionForSingleInitial,
          endingPosition
        )
      }
      var data = canvas.toDataURL()
      document.body.removeChild(canvas)
      return data
    }
  }
}
</script>
