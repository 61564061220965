<template>
  <div class="sidebar" @click.stop="() => {}">
    <div class="main-menu">
      <vue-perfect-scrollbar
        class="scroll"
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
      >
        <ul class="list-unstyled">
          <li
            v-for="(item, index) in filteredMenuItems(menuItems)"
            :class="{
              active:
                (selectedParentMenu === item.id && viewingParentMenu === '') ||
                viewingParentMenu === item.id
            }"
            :key="`parent_${index}`"
            :data-flag="item.id"
          >
            <a
              v-if="item.newWindow"
              :href="item.to"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i :class="item.icon" />
              {{ $t(item.label) }}
            </a>
            <a
              v-else-if="item.subs && item.subs.length > 0"
              @click.prevent="openSubMenu($event, item)"
              :href="`#${item.to}`"
            >
              <i :class="item.icon" /> {{ $t(item.label) }}
            </a>
            <router-link
              v-else
              @click.native="changeSelectedParentHasNoSubmenu(item.id)"
              :to="item.to"
            >
              <i :class="item.icon" />
              {{ $t(item.label) }}
            </router-link>
          </li>
        </ul>
      </vue-perfect-scrollbar>
    </div>

    <div class="sub-menu">
      <vue-perfect-scrollbar
        class="scroll"
        :settings="{ suppressScrollX: true, wheelPropagation: false }"
      >
        <ul
          v-for="(item, itemIndex) in filteredMenuItems(menuItems)"
          :class="{
            'list-unstyled': true,
            'd-block':
              (selectedParentMenu === item.id && viewingParentMenu === '') ||
              viewingParentMenu === item.id
          }"
          :data-parent="item.id"
          :key="`sub_${item.id}`"
        >
          <li
            v-for="(sub, subIndex) in filteredMenuItems(item.subs)"
            :key="`sub_${subIndex}`"
            :class="{
              'has-sub-item': sub.subs && sub.subs.length > 0,
              active: $route.path.indexOf(sub.to) > -1
            }"
          >
            <hr
              v-if="
                sub.id === 'admin-affiliates' && hasAffiliateContext(menuItems)
              "
            />
            <a
              v-if="sub.newWindow"
              :href="sub.to"
              rel="noopener noreferrer"
              target="_blank"
            >
              <i :class="sub.icon" />
              <span>{{ $t(sub.label) }}</span>
            </a>
            <template v-else-if="sub.subs && sub.subs.length > 0">
              <b-link
                v-b-toggle="`menu_${itemIndex}_${subIndex}`"
                variant="link"
                class="rotate-arrow-icon opacity-50"
              >
                <i class="simple-icon-arrow-down"></i>
                <span class="d-inline-block">{{ $t(sub.label) }}</span>
              </b-link>
              <b-collapse
                :visible="getExpandableMenus(sub.label)"
                :id="`menu_${itemIndex}_${subIndex}`"
              >
                <ul class="list-unstyled third-level-menu">
                  <li
                    v-for="(thirdLevelSub, thirdIndex) in filteredMenuItems(
                      sub.subs
                    )"
                    :key="`third_${itemIndex}_${subIndex}_${thirdIndex}`"
                    :class="{
                      'third-level-menu': true,
                      active: $route.path === thirdLevelSub.to
                    }"
                  >
                    <a
                      v-if="thirdLevelSub.newWindow"
                      :href="thirdLevelSub.to"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <i :class="thirdLevelSub.icon" />
                      <span>{{ $t(thirdLevelSub.label) }}</span>
                    </a>
                    <router-link v-else :to="thirdLevelSub.to">
                      <i :class="thirdLevelSub.icon" />
                      <span>{{ $t(thirdLevelSub.label) }}</span>
                    </router-link>
                  </li>
                </ul>
              </b-collapse>
            </template>
            <router-link v-else :to="sub.to">
              <i :class="sub.icon" />
              <span>{{ $t(sub.label) }}</span>
            </router-link>
            <hr
              v-if="
                sub.id === 'advanced-search' ||
                  sub.id === 'dynamic-affiliate-menu'
              "
            />
          </li>
        </ul>
      </vue-perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import {
  menuHiddenBreakpoint,
  subHiddenBreakpoint,
  RouteHistory,
  CompanyContext,
  AffiliateContext
} from '../../constants/config'
import menuItems from '../../constants/menu'
import bus from '../../main'

export default {
  data () {
    return {
      selectedParentMenu: '',
      menuItems,
      viewingParentMenu: ''
    }
  },
  beforeCreate () {
    bus.$on('refreshhistory', obj => {
      this.refreshhistory(obj)
    })

    bus.$on('setcompanycontext', route => {
      this.setCompanyContext(route)
    })

    bus.$on('setaffiliatecontext', route => {
      this.setAffiliateContext(route)
    })
  },

  created () {
    bus.$on('opencompanysubmenu', () => {
      this.openSubMenu(
        null,
        menuItems.find(x => x.id === 'company')
      )
    })

    bus.$on('openaffiliatesubmenu', () => {
      this.openSubMenu(
        null,
        menuItems.find(x => x.id === 'affiliates')
      )
    })
  },

  mounted () {
    if (
      this.$route.params.companyID !== undefined &&
      this.$route.params.companyID !== null
    ) {
      this.setCompanyContext(this.$route.path)
    }

    this.selectMenu()
    window.addEventListener('resize', this.handleWindowResize)
    document.addEventListener('click', this.handleDocumentClick)
    this.handleWindowResize()

    bus.$emit('buildcompanycontext')
    bus.$emit('buildaffiliatecontext')
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleDocumentClick)
    window.removeEventListener('resize', this.handleWindowResize)
    bus.$off('refreshhistory')
    bus.$off('setcompanycontext')
    bus.$off('opencompanysubmenu')
  },

  methods: {
    ...mapMutations([
      'changeSideMenuStatus',
      'addMenuClassname',
      'changeSelectedMenuHasSubItems'
    ]),
    getExpandableMenus (label) {
      const result =
        label.match(/^Affiliate #[0-9]+$/) || label.match(/^Company #[0-9]+$/)
      return !!result
    },
    selectMenu () {
      const currentParentUrl = this.$route.path
        .split('/')
        .filter(x => x !== '')[1]
      if (currentParentUrl !== undefined || currentParentUrl !== null) {
        this.selectedParentMenu = currentParentUrl.toLowerCase()
      } else {
        this.selectedParentMenu = 'dashboards'
      }
      this.isCurrentMenuHasSubItem()
    },
    isCurrentMenuHasSubItem () {
      const menuItem = this.menuItems.find(
        x => x.id === this.selectedParentMenu
      )
      const isCurrentMenuHasSubItem = !!(
        menuItem &&
        menuItem.subs &&
        menuItem.subs.length > 0
      )
      if (isCurrentMenuHasSubItem !== this.selectedMenuHasSubItems) {
        if (!isCurrentMenuHasSubItem) {
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
            selectedMenuHasSubItems: false
          })
        } else {
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
            selectedMenuHasSubItems: true
          })
        }
      }

      return isCurrentMenuHasSubItem
    },

    changeSelectedParentHasNoSubmenu (parentMenu) {
      this.selectedParentMenu = parentMenu
      this.viewingParentMenu = parentMenu
      this.changeSelectedMenuHasSubItems(false)
      this.changeSideMenuStatus({
        step: 0,
        classNames: this.menuType,
        selectedMenuHasSubItems: false
      })
    },

    openSubMenu (e, menuItem) {
      const selectedParent = menuItem.id
      const hasSubMenu = menuItem.subs && menuItem.subs.length > 0
      this.changeSelectedMenuHasSubItems(hasSubMenu)
      if (!hasSubMenu) {
        this.viewingParentMenu = selectedParent
        this.selectedParentMenu = selectedParent
        this.toggle()
      } else {
        const currentClasses = this.menuType
          ? this.menuType.split(' ').filter(x => x !== '')
          : ''

        if (!currentClasses.includes('menu-mobile')) {
          if (
            currentClasses.includes('menu-sub-hidden') &&
            (this.menuClickCount === 2 || this.menuClickCount === 0)
          ) {
            // Shift main page content to the right for nav sidebar
            document.getElementById('app-container-body').classList.value = ''
            document
              .getElementById('app-container-body')
              .classList.add('main-shifted-right')
            this.changeSideMenuStatus({
              step: 3,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu
            })
          } else if (
            currentClasses.includes('menu-hidden') &&
            (this.menuClickCount === 1 || this.menuClickCount === 3)
          ) {
            this.changeSideMenuStatus({
              step: 2,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu
            })
          } else if (
            currentClasses.includes('menu-default') &&
            !currentClasses.includes('menu-sub-hidden') &&
            (this.menuClickCount === 1 || this.menuClickCount === 3)
          ) {
            this.changeSideMenuStatus({
              step: 0,
              classNames: this.menuType,
              selectedMenuHasSubItems: hasSubMenu
            })
          }
        } else {
          this.addMenuClassname({
            classname: 'sub-show-temporary',
            currentClasses: this.menuType
          })
        }
        this.viewingParentMenu = selectedParent
      }
    },
    handleDocumentClick (e) {
      this.viewingParentMenu = ''
      this.selectMenu()
      this.toggle()
    },
    refreshhistory (to) {
      var histarrayinit = []

      // check to see if our localstorage history exists
      if (localStorage.getItem(RouteHistory) !== null) {
        // get the existing history out of localstorage and then add our route
        histarrayinit = JSON.parse(localStorage.getItem(RouteHistory))
      }

      var shortPath = to.fullPath.replace('/app', '')
      var historyPath = { path: to.fullPath, label: shortPath }
      this.AddHistoryItem(historyPath, histarrayinit, 10)
      localStorage.setItem(RouteHistory, JSON.stringify(histarrayinit))

      // after refreshing history make sure to repaint the sidemenu
      bus.$emit('repainthistory')
    },
    setCompanyContext (url) {
      var companyRouteIndex = url.indexOf('company/')
      if (companyRouteIndex > -1) {
        var companyRoute = url.substring(companyRouteIndex)
        var routeStrings = companyRoute.split('/')
        var companyID = parseInt(routeStrings[1])
        if (Number.isInteger(companyID)) {
          localStorage.setItem(CompanyContext, companyID)

          bus.$emit('buildcompanycontext')
        }
      }
    },
    setAffiliateContext (url) {
      var affiliateRouteIndex = url.indexOf('affiliate/')
      if (affiliateRouteIndex > -1) {
        var affiliateRoute = url.substring(affiliateRouteIndex)
        var routeStrings = affiliateRoute.split('/')
        var affiliateID = parseInt(routeStrings[1])
        if (Number.isInteger(affiliateID)) {
          localStorage.setItem(AffiliateContext, affiliateID)

          bus.$emit('buildaffiliatecontext')
        }
      }
    },
    // to keep track of route history we use a custom array that acts as a length limited queue
    // this allows us to push items on the top and items will drop off automatically when it reaches a certain length
    AddHistoryItem (historyPath, historyArray, maxlength) {
      // if we are at the defined max length then remove the last item
      if (historyArray.length >= maxlength) {
        historyArray.pop()
      }

      // remove duplicate instances of the route path from the list
      var removeIndex = _.findIndex(historyArray, { path: historyPath.path })
      if (removeIndex > -1) {
        historyArray.splice(removeIndex, 1)
      }

      // remove no-history item if present
      removeIndex = _.findIndex(historyArray, {
        label: this.$t('menu.no-history')
      })
      if (removeIndex > -1) {
        historyArray.splice(removeIndex, 1)
      }

      // add the history item to the top of the list
      historyArray.unshift(historyPath)

      return historyArray
    },
    toggle () {
      const currentClasses = this.menuType.split(' ').filter(x => x !== '')
      if (
        currentClasses.includes('menu-sub-hidden') &&
        this.menuClickCount === 3
      ) {
        // Shift main page content back from the right
        document
          .getElementById('app-container-body')
          .classList.remove('main-shifted-right')
        this.changeSideMenuStatus({
          step: 2,
          classNames: this.menuType,
          selectedMenuHasSubItems: this.selectedMenuHasSubItems
        })
      } else if (
        currentClasses.includes('menu-hidden') ||
        currentClasses.includes('menu-mobile')
      ) {
        if (!(this.menuClickCount === 1 && !this.selectedMenuHasSubItems)) {
          this.changeSideMenuStatus({
            step: 0,
            classNames: this.menuType,
            selectedMenuHasSubItems: this.selectedMenuHasSubItems
          })
        }
      }
    },

    // Resize
    handleWindowResize (event) {
      if (event && !event.isTrusted) {
        return
      }
      const nextClasses = this.getMenuClassesForResize(this.menuType)
      this.changeSideMenuStatus({
        step: 0,
        classNames: nextClasses.join(' '),
        selectedMenuHasSubItems: this.selectedMenuHasSubItems
      })
    },
    getMenuClassesForResize (classes) {
      let nextClasses = classes.split(' ').filter(x => x !== '')
      const windowWidth = window.innerWidth

      if (windowWidth < menuHiddenBreakpoint) {
        nextClasses.push('menu-mobile')
      } else if (windowWidth < subHiddenBreakpoint) {
        nextClasses = nextClasses.filter(x => x !== 'menu-mobile')
        if (
          nextClasses.includes('menu-default') &&
          !nextClasses.includes('menu-sub-hidden')
        ) {
          nextClasses.push('menu-sub-hidden')
        }
      } else {
        nextClasses = nextClasses.filter(x => x !== 'menu-mobile')
        if (
          nextClasses.includes('menu-default') &&
          nextClasses.includes('menu-sub-hidden')
        ) {
          nextClasses = nextClasses.filter(x => x !== 'menu-sub-hidden')
        }
      }
      return nextClasses
    },

    // For UserRole and userPermissions
    filteredMenuItems (menuItems) {
      var permissions = JSON.parse(localStorage.getItem('userPermissions')).map(
        p => p.permissionName
      )
      menuItems = menuItems
        ? menuItems.filter(
          x =>
            (!x.roles ||
                (x.roles && x.roles.includes(this.currentUser.role))) &&
              (x.meta === undefined ||
                (x.meta.permission &&
                  permissions.includes(x.meta.permission.toString())))
        )
        : []
      return menuItems
    },
    hasAffiliateContext (menuItems) {
      return (
        menuItems
          .find(i => i.id === 'affiliates')
          .subs.find(s => s.id === 'dynamic-affiliate-menu') !== undefined
      )
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      menuType: 'getMenuType',
      menuClickCount: 'getMenuClickCount',
      selectedMenuHasSubItems: 'getSelectedMenuHasSubItems'
    })
  },
  watch: {
    $route (to, from) {
      if (to.path !== from.path) {
        const toParentUrl = to.path.split('/').filter(x => x !== '')[1]
        if (toParentUrl !== undefined || toParentUrl !== null) {
          this.selectedParentMenu = toParentUrl.toLowerCase()
        } else {
          this.selectedParentMenu = 'dashboards'
        }
        this.selectMenu()
        this.toggle()
        this.changeSideMenuStatus({
          step: 0,
          classNames: this.menuType,
          selectedMenuHasSubItems: this.selectedMenuHasSubItems
        })

        window.scrollTo(0, top)
      }
    }
  }
}
</script>

<style scoped>
.sub-menu ul li span {
  display: inline-block;
  overflow-wrap: break-word;
  width: 80%;
}
</style>
