<template>
  <b-modal
    id="modalbasic"
    size="lg"
    ref="modalbasic"
    @hide="close()"
    v-model="isVisible"
    :title="this.title"
    :no-close-on-backdrop="true"
    @cancel="close()"
  >
    <h3>
      You are about to leave when you have unsaved changes. Do you wish to exit?
    </h3>
    <template #modal-footer="{ cancel }">
      <b-button
        size="lg"
        variant="outline-danger"
        class="mr-1"
        @click="discard()"
      >
        Leave and Discard Changes
      </b-button>
      <b-button size="lg" variant="success" class="mr-1" @click="cancel">
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped></style>

<script>
import { mapMutations } from 'vuex'
import DirtyDataMixin from '../../mixins/DirtyDataMixin.vue'

export default {
  name: 'dirtyDataModal',
  mixins: [DirtyDataMixin],

  data: () => ({
    title: undefined,
    route: undefined,
    isVisible: false
  }),

  async created () {},

  methods: {
    ...mapMutations(['setModalDangerMessage', 'setModalInfoMessage']),

    async show (opts = {}) {
      this.title = opts.title
      this.route = opts.route

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      this.isVisible = true
    },

    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }
      this.isVisible = false
    },
    async cancel () {
      this.close()
      console.log('cancel button clicked')
    },
    async discard () {
      this.setDirtyData(false)
      this.close()
      console.log(this.route.fullPath)
      console.log('discard button clicked')
      this.$router.push(this.route.fullPath)
    }
  }
}
</script>
