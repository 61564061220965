<template>
  <div class="sidebar">
    <div
      class="filter-menu__backdrop"
      @click="closeFilterMenu"
      v-if="isFilterMenuOpen"
    ></div>
    <transition name="slide">
      <div v-if="isFilterMenuOpen" class="filter-menu__panel">
        <div>
          <div class="filter-menu__header mb-5">
            <h4 style="font-weight: 700;">{{ $t('datatable.filters') }}</h4>
            <button class="full-page-modal__close" @click="closeFilterMenu">
              ×
            </button>
          </div>
          <div class="filter-menu__filters">
            <div v-if="filters.length > 0">
              <div
                v-for="filter in filters"
                :key="`${filter.columnName}-input-container`"
              >
                <b-form-group
                  v-if="
                    filter.type === filterTypes.TEXT ||
                      filter.type === filterTypes.DATE
                  "
                  :label="filter.columnDisplayName"
                >
                  <div
                    v-for="filterSearch in filter.search"
                    :key="filterSearch.filterSearchID"
                  >
                    <b-row align-v="baseline" class="mb-1">
                      <b-col cols="1" style="padding: 0 0 0 10px;">
                        <b-dropdown
                          class="dropdown-menu-left"
                          right
                          variant="empty"
                          toggle-class="p-0 btn-slot-color"
                          menu-class="mt-3"
                          no-caret
                        >
                          <template slot="button-content">
                            <i
                              :id="
                                `${filterSearch.filterSearchID}-comparison-type-selector`
                              "
                              class="iconsminds-filter-2"
                              style="font-size: 0.8rem;"
                            />
                            <b-tooltip
                              :target="
                                `${filterSearch.filterSearchID}-comparison-type-selector`
                              "
                              placement="bottomright"
                              :title="
                                getComparisonTypeString(
                                  filterSearch.comparisonType
                                )
                              "
                            >
                            </b-tooltip>
                          </template>
                          <b-dropdown-item
                            v-for="comparisonType in filterComparisonTypes"
                            :key="
                              `${filterSearch.filterSearchID}-${comparisonType}`
                            "
                            :class="{
                              'active-comparison-type':
                                filterSearch.comparisonType === comparisonType
                            }"
                            @click="
                              changedComparisonType(
                                filterSearch,
                                comparisonType
                              )
                            "
                            :hidden="
                              filter.type === filterTypes.DATE &&
                                comparisonType === filterComparisonTypes.LIKE
                            "
                          >
                            {{ getComparisonTypeString(comparisonType) }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                      <b-col cols="10">
                        <b-form-input
                          v-model="filterSearch.value"
                          :id="
                            `${filter.columnName}-${filterSearch.filterSearchID}-input`
                          "
                          @input="filterValueChanged()"
                          :type="filter.dataType"
                        />
                      </b-col>
                      <b-col cols="1" style="padding: 0;">
                        <!--
                        A duplicate filter is an additional search on the same column.
                        You cannot remove the main column search, but any additional filters
                        on the same column can be deleted
                        -->
                        <button
                          v-if="filterSearch.isDuplicate"
                          class="filter-menu__duplicate"
                          @click="removeFilter(filter, filterSearch)"
                        >
                          ×
                        </button>
                        <button
                          v-else-if="!filterSearch.isDuplicate"
                          class="filter-menu__duplicate"
                          @click="duplicateFilter(filter)"
                        >
                          +
                        </button>
                      </b-col>
                    </b-row>
                  </div>
                </b-form-group>
                <b-form-group
                  v-else-if="filter.type === filterTypes.BOOL"
                  :label="filter.columnDisplayName"
                  v-slot="{ filterBool }"
                >
                  <div
                    class="readonly-input-field"
                    style="display: flex; flex-direction: column; gap: 15px;"
                  >
                    <b-form-radio
                      v-model="filter.search[0].value"
                      :filterBool="filterBool"
                      :name="`${filter.search[0].filterSearchID}-radios`"
                      :value="1"
                      @change="filterValueChanged()"
                    >
                      Yes
                    </b-form-radio>

                    <b-form-radio
                      v-model="filter.search[0].value"
                      :filterBool="filterBool"
                      :name="`${filter.search[0].filterSearchID}-radios`"
                      :value="0"
                      @change="filterValueChanged()"
                    >
                      No
                    </b-form-radio>
                  </div>
                </b-form-group>
                <b-form-group
                  v-else-if="filter.type === filterTypes.ACTIVESTATUS"
                  :label="filter.columnDisplayName"
                  v-slot="{ filterActiveStatus }"
                >
                  <div
                    class="readonly-input-field"
                    style="display: flex; flex-direction: column; gap: 15px;"
                  >
                    <b-form-radio
                      v-model="filter.search[0].value"
                      :filterActiveStatus="filterActiveStatus"
                      name="active-status-radios"
                      :value="-1"
                      @change="filterValueChanged()"
                    >
                      All
                    </b-form-radio>

                    <b-form-radio
                      v-model="filter.search[0].value"
                      :filterActiveStatus="filterActiveStatus"
                      name="active-status-radios"
                      :value="1"
                      @change="filterValueChanged()"
                    >
                      Active
                    </b-form-radio>

                    <b-form-radio
                      v-model="filter.search[0].value"
                      :filterActiveStatus="filterActiveStatus"
                      name="active-status-radios"
                      :value="0"
                      @change="filterValueChanged()"
                    >
                      Inactive
                    </b-form-radio>
                  </div>
                </b-form-group>
                <b-form-group
                  v-else-if="filter.type === filterTypes.DUEDATE"
                  :label="filter.columnDisplayName"
                  v-slot="{ filterDueDate }"
                >
                  <div
                    class="readonly-input-field"
                    style="display: flex; flex-direction: column; gap: 15px;"
                  >
                    <b-form-radio
                      v-model="filter.search[0].value"
                      :filterDueDate="filterDueDate"
                      name="due-date-radios"
                      :value="-1"
                      @change="filterValueChanged()"
                    >
                      All
                    </b-form-radio>

                    <b-form-radio
                      v-model="filter.search[0].value"
                      :filterDueDate="filterDueDate"
                      name="due-date-radios"
                      :value="0"
                      @change="filterValueChanged()"
                    >
                      Upcoming
                    </b-form-radio>

                    <b-form-radio
                      v-model="filter.search[0].value"
                      :filterDueDate="filterDueDate"
                      name="due-date-radios"
                      :value="1"
                      @change="filterValueChanged()"
                    >
                      Overdue
                    </b-form-radio>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <div class="filter-menu__footer">
          <b-button
            block
            variant="danger"
            class="mt-4"
            size="lg"
            @click="clearFilters()"
          >
            {{ $t('button.clear') }}
          </b-button>
          <b-button block variant="success" size="lg" @click="saveFilters()">
            {{ saveFiltersButtonText }}
          </b-button>
        </div>
      </div>
    </transition>
  </div>
</template>
<style>
.btn-slot-color,
.btn-slot-color:hover {
  color: inherit;
}
</style>
<style scoped>
.active-filter-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}
</style>
<script>
import {
  store,
  mutations,
  filterTypes,
  filterComparisonTypes
} from '../../store/FilterMenu'

export default {
  data () {
    return {
      saveFiltersButtonText: 'Save Filter'
    }
  },
  methods: {
    closeFilterMenu: mutations.toggleFilterMenu,
    duplicateFilter: mutations.duplicateFilter,
    removeFilter: mutations.removeFilter,
    filterValueChanged () {
      var filtersCount = 0

      store.filters.forEach(filter => {
        filter.search.forEach(filterSearch => {
          if (
            (filter.type === filterTypes.TEXT ||
              filter.type === filterTypes.DATE) &&
            filterSearch.value.trim() !== ''
          ) {
            filtersCount++
          } else if (
            (filter.type === filterTypes.ACTIVESTATUS ||
              filter.type === filterTypes.BOOL) &&
            filterSearch.value !== -1
          ) {
            filtersCount++
          } else if (
            filter.type === filterTypes.DUEDATE &&
            filterSearch.value !== -1
          ) {
            // 1 would be equivalent to overdue and 0 (else) would be equivalent to upcoming
            // DueDate < GETDATE() for overdue and DueDate >= GETDATE() for upcoming
            filterSearch.comparisonType =
              filterSearch.value === 1
                ? filterComparisonTypes.LESSTHAN
                : filterComparisonTypes.GREATERTHANOREQUAL
            filtersCount++
          }
        })
      })

      if (filtersCount > 1) {
        this.saveFiltersButtonText = `Save (${filtersCount}) Filters`
      } else {
        this.saveFiltersButtonText = 'Save Filter'
      }
    },
    saveFilters () {
      mutations.toggleFilterMenu()
      this.$emit('filterChange')
    },
    clearFilters () {
      mutations.clearFilters()
      this.saveFiltersButtonText = 'Save Filter'
      this.$emit('filterChange')
    },
    changedComparisonType (filterSearch, comparisonType) {
      filterSearch.comparisonType = comparisonType
    },
    getComparisonTypeString (comparisonType) {
      return mutations.getComparisonTypeString(comparisonType)
    }
  },
  computed: {
    isFilterMenuOpen () {
      return store.isFilterMenuOpen
    },
    filters () {
      return store.filters
    },
    filterTypes () {
      return filterTypes
    },
    filterComparisonTypes () {
      return filterComparisonTypes
    }
  }
}
</script>
